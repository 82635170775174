import React, { ReactElement } from "react";
import ComparisonIcon from "../svg/comparisonIcon";
import GuidanceIcon from "../svg/GuidanceIcon";
import PrivacyIcon from "../svg/privacyIcon";
import colors from "@styles/variables/insurancesupersaverVariables.module.scss";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Expert Guidance on the Right Policy",
        description:
            "We go beyond simply offering affordable rates. When you receive your quote, you gain the ability to compare prices, read customer reviews, evaluate benefits, and more, ensuring you make an informed decision.",
        icon: <GuidanceIcon />,
    },
    {
        title: "Effortless Side-by-Side Comparisons",
        description:
            "Eliminate the need for multiple browser tabs. Our platform simplifies your insurance shopping experience. Through our efficient and comprehensive search, you can access multiple quotes from the industry's top providers, all conveniently in one place.",
        icon: <ComparisonIcon />,
    },
    {
        title: "Stringent Privacy Controls",
        description:
            "Your privacy matters to us just as much as it does to you. Whether you request quotes online, over the phone, or directly from carriers, rest assured that we will never share your information with any third parties.",
        icon: <PrivacyIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle={
                <>
                    What Does <strong>InsuranceSuperSaver.com</strong> Offer
                    You?
                </>
            }
            classes={{
                mainSectionClasses: "max-w-7xl mx-auto block px-4vw lg:px-4",
                mainTitleClasses: "text-center text-xl lg:text-2xl mb-10",
                innerSectionClasses: "lg:flex",
                oneBoxClasses:
                    "text-center mb-12 lg:border-l lg:first:border-l-0",
                iconClasses: " children:w-12 children:h-12 children:mx-auto",
                titleClasses: "font-bold text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm lg:text-base text-justify w-4/5 mx-auto mt-2",
            }}
            colors={{
                mainTitleColor: colors.assault,
                titleColor: colors.assault,
                descriptionColor: "#4788c7",
            }}
        />
    );
}
