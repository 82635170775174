import React, { ReactElement } from "react";

export default function ComparisonIcon(): ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
                fill="#dff0fe"
                d="M32 30.5c-2.623 0-5-1.614-5.997-4h11.994c-.997 2.386-3.374 4-5.997 4z"
            ></path>
            <path
                fill="#4788c7"
                d="M37.192 27c-1.057 1.816-3.034 3-5.192 3s-4.135-1.184-5.192-3h10.384m1.514-1H25.294A7 7 0 0032 31a7 7 0 006.706-5z"
            ></path>
            <path
                fill="#4788c7"
                d="M32 9.145l5.653 16.859C36.813 28.372 34.531 30 32 30s-4.813-1.628-5.653-3.995L32 9.145M32 6l-6.706 20A7 7 0 0032 31a7 7 0 006.706-5L32 6z"
            ></path>
            <path
                fill="#dff0fe"
                d="M8 30.5c-2.623 0-5-1.614-5.997-4h11.994c-.997 2.386-3.374 4-5.997 4z"
            ></path>
            <path
                fill="#4788c7"
                d="M13.192 27c-1.057 1.816-3.034 3-5.192 3s-4.135-1.184-5.192-3h10.384m1.514-1H1.294A7 7 0 008 31a7 7 0 006.706-5z"
            ></path>
            <path
                fill="#4788c7"
                d="M8 9.145l5.653 16.859C12.813 28.372 10.531 30 8 30s-4.813-1.628-5.653-3.995L8 9.145M8 6L1.294 26A7 7 0 008 31a7 7 0 006.706-5L8 6z"
            ></path>
            <path
                fill="none"
                stroke="#4788c7"
                strokeMiterlimit="10"
                d="M31.963 8.333C28.372 4.743 22.867 4.763 20 7.63"
            ></path>
            <path fill="#4788c7" d="M32 6a2 2 0 100 4 2 2 0 100-4z"></path>
            <path
                fill="none"
                stroke="#4788c7"
                strokeMiterlimit="10"
                d="M8.037 8.333c3.591-3.59 9.096-3.57 11.963-.703"
            ></path>
            <path
                fill="#4788c7"
                d="M22 4a2 2 0 11-4 0c0-1.105 1.438-2.469 2-3 .5.484 2 1.895 2 3z"
            ></path>
            <path fill="#b6dcfe" d="M18.5 7.5h3v27h-3z"></path>
            <path fill="#4788c7" d="M21 8v26h-2V8h2m1-1h-4v28h4V7z"></path>
            <path
                fill="#b6dcfe"
                d="M12.5 38.5V37c0-.827.673-1.5 1.5-1.5h2.5V35c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v.5H26c.827 0 1.5.673 1.5 1.5v1.5h-15z"
            ></path>
            <path
                fill="#4788c7"
                d="M20 32c1.654 0 3 1.346 3 3v1h3c.551 0 1 .449 1 1v1H13v-1c0-.551.449-1 1-1h3v-1c0-1.654 1.346-3 3-3m0-1c-2.2 0-4 1.8-4 4h-2c-1.1 0-2 .9-2 2v2h16v-2c0-1.1-.9-2-2-2h-2c0-2.2-1.8-4-4-4z"
            ></path>
            <path
                fill="#b6dcfe"
                d="M20 5.5a2.5 2.5 0 100 5 2.5 2.5 0 100-5z"
            ></path>
            <path
                fill="#4788c7"
                d="M20 6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2m0-1a3 3 0 100 6 3 3 0 000-6z"
            ></path>
            <g>
                <path fill="#4788c7" d="M8 6a2 2 0 100 4 2 2 0 100-4z"></path>
            </g>
        </svg>
    );
}
