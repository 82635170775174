import React, { ReactElement } from "react";

export default function PrivacyIcon(): ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
                fill="none"
                stroke="#4788c7"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M30 17.714V12c0-5.523-4.477-10-10-10S10 6.477 10 12v5.714"
            ></path>
            <path
                fill="#b6dcfe"
                d="M2.5 37.5V22c0-3.584 2.916-6.5 6.5-6.5h22c3.584 0 6.5 2.916 6.5 6.5v15.5h-35z"
            ></path>
            <path
                fill="#4788c7"
                d="M31 16c3.308 0 6 2.692 6 6v15H3V22c0-3.308 2.692-6 6-6h22m0-1H9a7 7 0 00-7 7v16h36V22a7 7 0 00-7-7z"
            ></path>
            <path
                fill="#dff0fe"
                d="M20 19.5a7.5 7.5 0 100 15 7.5 7.5 0 100-15z"
            ></path>
            <path
                fill="#4788c7"
                d="M20 20c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7m0-1a8 8 0 100 16 8 8 0 000-16z"
            ></path>
            <path fill="#4788c7" d="M20 24a3 3 0 100 6 3 3 0 100-6z"></path>
            <path
                fill="#fff"
                d="M22.5 23a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"
            ></path>
        </svg>
    );
}
