import React, { ReactElement } from "react";

export default function GuidanceIcon(): ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
                fill="#98ccfd"
                d="M20 38.5C9.799 38.5 1.5 30.201 1.5 20S9.799 1.5 20 1.5 38.5 9.799 38.5 20 30.201 38.5 20 38.5z"
            ></path>
            <path
                fill="#4788c7"
                d="M20 2c9.925 0 18 8.075 18 18s-8.075 18-18 18S2 29.925 2 20 10.075 2 20 2m0-1C9.507 1 1 9.507 1 20s8.507 19 19 19 19-8.507 19-19S30.493 1 20 1z"
            ></path>
            <path
                fill="#b6dcfe"
                d="M20 36.5c-9.098 0-16.5-7.402-16.5-16.5S10.902 3.5 20 3.5 36.5 10.902 36.5 20 29.098 36.5 20 36.5z"
            ></path>
            <path
                fill="#fff"
                d="M20 4c8.822 0 16 7.178 16 16s-7.178 16-16 16S4 28.822 4 20 11.178 4 20 4m0-1C10.611 3 3 10.611 3 20s7.611 17 17 17 17-7.611 17-17S29.389 3 20 3z"
            ></path>
            <path
                fill="#fff"
                d="M36 22l-5-2 5-2.071zM4 22l5-2-5-2.071zM17.929 4L20 9l2-5zm0 32L20 31l2 5z"
            ></path>
            <path
                fill="#dff0fe"
                d="M17.369 17.369L28.77 11.23l-6.139 11.401L11.23 28.77z"
            ></path>
            <path
                fill="#4788c7"
                d="M27.539 12.461l-5.277 9.801-9.801 5.277 5.277-9.801 9.801-5.277M30 10l-13 7-7 13 13-7 7-13z"
            ></path>
            <g>
                <path
                    fill="#fff"
                    d="M17.122 17.829l5.049 5.049L11.23 28.77z"
                ></path>
                <path
                    fill="#4788c7"
                    d="M17.243 18.657l4.099 4.099-8.882 4.782 4.783-8.881M17 17l-7 13 13-7-6-6z"
                ></path>
            </g>
        </svg>
    );
}
